import { requireFun } from '@/utils'

export const baseProtoFormField = [
  {
    label: '原型分组名称',
    prop: 'name',
    colNum: 24,
    placeholder: '输入原型分组名称',
    rule: [
      {
        required: true,
        message: requireFun('原型分组名称')
      }
    ]
  }
]


export const basePictureFormField = [
  {
    label: '图片分组名称',
    prop: 'name',
    colNum: 24,
    placeholder: '输入图片分组名称',
    rule: [
      {
        required: true,
        message: requireFun('图片分组名称')
      }
    ]
  }
]
